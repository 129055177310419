// @desc - pxをvwに変換します。
// @param {Number} $px - 指定したいピクセル値。
// @param {String} $width [375] -画面サイズのピクセル値。
// @example - vw(30) => 8vw
// @example - vw(60, 750) => 8vw
/*@function vw($px, $width: 375) {
    @return (strip-unit($px) / strip-unit($width)) * 100vw;
}*/

@function _vw($px, $width: 1366) {
    @return (strip-unit($px) / strip-unit($width)) * 100vw;
}
@function _vh($px, $height: 768) {
    @return (strip-unit($px) / strip-unit($height)) * 100vh;
}
