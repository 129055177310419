// ==========================================================
// 	Layout - main
// ==========================================================


/*.l-page{
}

@include _mq-down(md) {
    .l-page{
    }
}*/
@font-face {
  font-family: "A1Mincho";
  src: url('../../assets/doc/A-OTF-A1MinchoStd-Bold.otf') format('opentype');
}


a {
    transition: 0.2s;
}
@include _mq-up(md) {
    a:hover {
        opacity: 0.7;
    }
    a.no-opa:hover {
        opacity: 1;
    }
}
body:not(#home) p {
    font-size: _vw(14);
    line-height: 2;
    letter-spacing: 0.1em;
    /*@include _mq-up(xxl) {
        font-size: 14px;
    }*/
    @include _mq-down(sm) {
        font-size: _vw(12, 375);
        letter-spacing: 0.05em;
    }
}

.sec-border {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        top: -150px;
        left: 0;
        right: 0;
        width: 1px;
        height: 200px;
        background-color: #000;
        margin: 0 auto;
        z-index: 1;
    }
}

// responsive display - utility //抜粋//
.u-display {
    &-sm {
        @include _mq-up(sm) {
            display: none !important;
        }
    }
    &-md {
        @include _mq-up(md) {
            display: none !important;
        }
    }
}

.u-hide {
    &-sm {
        @include _mq-down(sm) {
            display: none !important;
        }
    }
    &-md {
        @include _mq-down(md) {
            display: none !important;
        }
    }
}

@include _mq-up(md) {
    .u-d-flex {
        display: flex;
        &--center {
            display: flex;
            justify-content: center;
        }
        &--between {
            display: flex;
            justify-content: space-between;
        }
        &--align-center {
            align-items: center;
        }
        &--wrap {
            flex-wrap: wrap;
        }
    }
}
@include _mq-down(md) {
    .u-d-flex {
        &.md {
            display: flex;
        }
        &--center.md {
            display: flex;
            justify-content: center;
        }
        &--between.md {
            display: flex;
            justify-content: space-between;
        }
        &--align-center.md {
            align-items: center;
        }
        &--wrap.md {
            flex-wrap: wrap;
        }
    }
}

@include _mq-down(md) {
    .u-d-flex-md {
        display: flex;
        &--center {
            display: flex;
            justify-content: center;
        }
        &--between {
            display: flex;
            justify-content: space-between;
        }
        &--align-center {
            align-items: center;
        }
        &--wrap {
            flex-wrap: wrap;
        }
    }
}

.u-text-palt {
    font-feature-settings: "palt";
}
.u-text-punct-r {
    letter-spacing: -0.25em;
}
.u-text-punct-l {
    letter-spacing: -0.25em;
}
span.u-d-in {
    display: inline-block;
}

@include _mq-up(md) { //PC時telリンク機能停止
    a[href*="tel:"] {
        text-decoration: none;
        cursor: default;
        pointer-events: none
    }
}

/*object-fit-image*//*imgタグにクラス付与*/
.object-fit {
  object-fit: cover;
  /*object-position: bottom;*/
  font-family: 'object-fit: cover;';
  /*font-family: 'object-fit: cover; object-position: bottom;';*/
}

/*リンク無し*/
.close a {
    pointer-events: none;
    opacity: 0.3;
}
