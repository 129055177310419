// Object - project - title

/**
 * 下層ページ ヘッド
 **/
.page-head {
    width: 100%;
    height: _vw(330);
    padding-top: _vw(170);
    //background: #eee;
    position: relative;
    h2 {
        font-size: _vw(25);
        font-weight: bold;
        padding-bottom: _vw(22);
        line-height: 1;
        position: relative;
        text-align: center;
        letter-spacing: 0.15em;
        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            margin: 0 auto;
            background: linear-gradient(to right, #ffbe55, $color-brand);
            width: _vw(30);
            height: _vw(3);
            display: inline-block;
        }
    }
    @include _mq-down(sm) {
        height: _vw(170, 375);
        padding-top: _vw(70, 375);
        h2 {
            font-size: _vw(20, 375);
            padding-bottom: _vw(15, 375);
            &::before {
                width: _vw(30, 375);
                height: 2px;
            }
        }
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        background: url("../../assets/img/page/bg_head_white.png") center center / cover;
        width: 100%;
        height: 100%;
    }
    /*&.Company {
        background: url("../../assets/img/page/bg_head_company.jpg") center center / cover;
    }*/
    &.About {
        background: url("../../assets/img/page/bg_head_about.jpg") center center / cover;
    }
    &.Access {
        background: url("../../assets/img/page/bg_head_access.jpg") center center / cover;
    }
    &.Method {
        background: url("../../assets/img/page/bg_head_method.jpg") center center / cover;
    }
    &.Opencampus {
        background: url("../../assets/img/page/bg_head_opencampus.jpg") center center / cover;
    }
    &.Other {
        background: url("../../assets/img/page/bg_head_other.jpg") center center / cover;
    }
}

/**
 * 下層ページ リードタイトル（大）
 **/
.page-head-lead {
    font-size: _vw(35);
    line-height: 1.8571;
    letter-spacing: 0.05em;
    text-align: center;
    @include _mq-down(sm) {
        font-size: _vw(20, 375);
    }
}

/**
 * 下層ページ コンテンツタイトル
 **/
.content-title {
    font-size: _vw(23);
    font-weight: bold;
    padding-bottom: _vw(20);
    line-height: 1;
    position: relative;
    text-align: center;
    letter-spacing: 0.05em;
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        margin: 0 auto;
        background: $color-brand;
        width: _vw(30);
        height: _vw(3);
        display: inline-block;
    }
    @include _mq-down(sm) {
        font-size: _vw(18, 375);
        padding-bottom: _vw(15, 375);
        &::before {
            width: _vw(25, 375);
            height: 2px;
        }
    }
}

/**
 * 下線タイトル
 **/
.unbar-title {
    text-align: center;
    font-weight: bold;
    font-size: _vw(23);
    letter-spacing: .15em;
    padding-bottom: .5em;
    border-bottom: solid 1px #ccc;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.4;
    @include _mq-down(sm) {
        font-size: _vw(18, 375);
        letter-spacing: .1em;
        padding-bottom: .25em;
    }
}

/**
 * 文字のみタイトル
 **/
.text-title {
    text-align: center;
    font-weight: bold;
    font-size: _vw(23);
    letter-spacing: .15em;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.4;
    @include _mq-down(sm) {
        font-size: _vw(18, 375);
        letter-spacing: .1em;
    }
}

/**
 * 下線タイトル 左詰め
 **/
.unbar-title-secandly {
    font-weight: bold;
    font-size: _vw(18);
    letter-spacing: .05em;
    //line-height: 1.4;
    position: relative;
    padding-bottom: _vw(15);
    &::before {
        content: '';
        width: 100%;
        height: 1px;
        background: #c8c8c8;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    &::after {
        content: '';
        width: _vw(200);
        height: 1px;
        background: $color-brand;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    @include _mq-down(sm) {
        font-size: _vw(16, 375);
        padding-bottom: _vw(6, 375);
        line-height: 1.5;
    }
}
/**
 * 文字のみタイトル 左詰め
 **/
.text-title-secandly {
    font-weight: bold;
    font-size: _vw(18);
    letter-spacing: .05em;
    //line-height: 1.4;
    @include _mq-down(sm) {
        font-size: _vw(16, 375);
        line-height: 1.7;
    }
}

.title-secandly-under {
    margin-top: _vw(15);
    @include _mq-down(sm) {
        margin-top: _vw(10, 375);
    }
}

/**
 * 文字オレンジ背景タイトル 左詰め
 **/
.brand-box-title {
    display: inline-block;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: _vw(14);
    letter-spacing: 0.1em;
    padding: _vw(8) _vw(25);
    background-color: $color-brand;
    span {
        margin-left: _vw(-5);
    }
    @include _mq-down(sm) {
        font-size: _vw(12, 375);
        padding: _vw(8, 375) _vw(15, 375);
    }
}

/**
* 左オレンジ縦線タイトル
**/
.lbar-title {
    font-size: _vw(18);
    font-weight: bold;
    padding: _vw(6) 0 _vw(6) _vw(19);
    position: relative;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        background: $color-brand;
        width: _vw(5);
        height: _vw(30);
    }
    @include _mq-down(sm) {
        font-size: _vw(15, 375);
        padding: _vw(3, 375) 0 _vw(3, 375) _vw(10, 375);
        &::before {
            width: _vw(3, 375);
            height: 100%;
        }
    }
}

.lbar-title-under {
    margin-top: _vw(13);
    @include _mq-down(sm) {
        margin-top: _vw(8, 375);
    }
}
