// Object - project - link

.p-linkbox {
    width: 100%;
    a {
        display: flex;
        border: 1px solid #ccc;
        height: _vw(50);
        font-size: _vw(16);
        letter-spacing: 0.2em;
        justify-content: center;
        align-items: center;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%; //width: 0;
            height: 100%;
            background: #fff;
            transition: 0.2s;
            z-index: -1;
        }
        svg.icon-arrow-orange {
            width: _vw(5.6);
            height: _vw(8);
            fill: $color-brand;
            margin-right: _vw(10);
            transition: 0.2s;
        }
        &:hover {
            opacity: 1;
            color: #fff;
            border: 1px solid $color-brand;
            svg.icon-arrow-orange {
                fill: #fff; //svg
            }
        }
        &:hover::before {
            width: 100%;
            background: $color-brand;
        }
    }
    @include _mq-down(sm) {
        a {
            height: _vw(40, 375);
            font-size: _vw(13, 375);
            letter-spacing: 0.1em;
            svg.icon-arrow-orange {
                width: _vw(5.6, 375);
                height: _vw(8, 375);
                margin-right: _vw(10, 375);
            }
        }
    }
}

.p-linkbox.Method {
    margin: _vw(25) auto 0;
    width: _vw(300);
    @include _mq-down(sm) {
        margin: _vw(15, 375) auto 0;
        width: 100%;
    }
}

.p-target-linkbox {
    width: 100%;
    svg.icon-target-r {
        fill-rule: evenodd; //svg
        clip-rule: evenodd; //svg
        fill: #FFFFFF; //svg
        width: _vw(13);
        height: _vw(9);
    }
    a {
        display: flex;
        color: #fff;
        height: _vw(35);
        font-size: _vw(13);
        letter-spacing: 0.1em;
        justify-content: center;
        align-items: center;
        position: relative;
        svg.icon-target-r {
            margin-left: 12px;
        }
    }
    @include _mq-down(sm) {
        svg.icon-target-r {
            width: _vw(12, 375);
            height: _vw(9, 375);
        }
        a {
            height: _vw(35, 375);
            font-size: _vw(13, 375);
        }
    }
}

.p-target-linkbox.Method {
    margin: _vw(19) auto 0;
}

/*list*/
.p-target-linktext-list {
    li a {
        padding-right: _vw(16);
        background: url("../../assets/img/common/icon_target_gray50.svg") right center / _vw(12) _vw(10) no-repeat;
        @include _mq-down(sm) {
            padding-right: _vw(16, 375);
            background-size: _vw(12, 375) _vw(10, 375);
        }
    }
}


