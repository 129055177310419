// ==========================================================
//  Layout - main
// ==========================================================
/*.l-wrap {
    @include _wrapper($wrapper-width);
}*/
.l-wrap-wide {
    @include _wrapper($wrapper-width-wide);
}
.l-wrap-max {
    @include _wrapper-fluid($wrapper-width-max);
}
.l-wrap-fluid {
    @include _wrapper-fluid($wrapper-width);
}

.l-wrap {
    max-width: _vw(1000);
    margin-right: auto;
    margin-left: auto;
    @include _mq-down(sm) {
        max-width: 100%;
        padding-right: 20px;
        padding-left: 20px;
    }
}

.l-page {
    max-width: _vw(900);
    margin-right: auto;
    margin-left: auto;
    @include _mq-down(sm) {
        max-width: 100%;
        padding-right: 20px;
        padding-left: 20px;
    }
}

.l-wrap-fluid {
    max-width: _vw(1000);
    margin-right: auto;
    margin-left: auto;
    @include _mq-down(sm) {
        max-width: 100%;
    }
}
