// Object - project - list

.p-list-asterisk { //「※」
    & li {
        position: relative;
        padding-left: 1em;
    }
    & li:before {
        content: '※';
        position: absolute;
        margin-left: -1em;
    }
}

.p-list-dot { //「・」
    & li {
        position: relative;
        padding-left: 1em;
    }
    & li:before {
        content: '・';
        position: absolute;
        margin-left: -1em;
    }
}

.p-list-square { //「■」
    & li {
        position: relative;
        padding-left: 1em;
    }
    & li:before {
        content: '■';
        position: absolute;
        margin-left: -1em;
    }
}

.p-list-circleline { //「⚪︎」
    & li {
        position: relative;
        padding-left: 1em;
    }
    & li:before {
        content: '⚪︎';
        position: absolute;
        margin-left: -1em;
    }
}

.p-list-asterisk-counter { //「※1」「※2」
    counter-reset: number 0;
    & li {
        position: relative;
        padding-left: 2.5em;
    }
    & li:before {
        counter-increment: number 1;
        content: "※" counter(number);
        position: absolute;
        margin-left: -2.5em;
    }
}

.p-list-inject { //「注）」
    & li {
        position: relative;
        padding-left: 2em;
    }
    & li:before {
        content: '注）';
        position: absolute;
        margin-left: -2em;
    }
}

.p-list-inject-counter { //「1）」「2）」
    counter-reset: number 0;
    & li {
        position: relative;
        padding-left: 2em;
    }
    & li:before {
        counter-increment: number 1;
        content: counter(number) " ）";
        position: absolute;
        margin-left: -2em;
    }
}

.p-list-d-inject-counter { //「（1）」「（2）」
    counter-reset: number 0;
    & > li {
        position: relative;
        padding-left: 2em;
    }
    & > li:before {
        counter-increment: number 1;
        content: "（"counter(number) "）";
        position: absolute;
        margin-left: -2.5em;
    }
}

.p-list-inject-parenth-counter { //「注1）」「注2）」
    counter-reset: number 0;
    & li {
        position: relative;
        padding-left: 3em;
    }
    & li:before {
        counter-increment: number 1;
        content: "注" counter(number) " ）";
        position: absolute;
        margin-left: -3em;
    }
}

.p-list-period-counter { //「1.」「2.」
    counter-reset: number 0;
    & li {
        position: relative;
        padding-left: 1.67em;
    }
    & li:before {
        counter-increment: number 1;
        content: counter(number) ". ";
        position: absolute;
        margin-left: -1.5em;
    }
}

.p-list-circle-number { // ①、② 打ち文字
    padding-left: 1em;
    span {
        margin-left: -1em;
        padding-right: 0.1em;
    }
}
