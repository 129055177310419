//Noto Sans JP
//@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);

// よく使うGoogleFonts
// Noto Sans JP
// @import url(//fonts.googleapis.com/earlyaccess/notosansjp.css);
//
// Montserrat (Gothemの代替)
@import url(//fonts.googleapis.com/css?family=Montserrat);
//
// Lato (Gill Sansの代替)
// @import url(//fonts.googleapis.com/css?family=Lato);
//
// Poppings
//@import url(https://fonts.googleapis.com/css?family=Poppins:600);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,500;0,600;0,700;1,400&display=swap);
//
// 組み合わせて指定する場合
// @import url(//fonts.googleapis.com/css?family=Lato|Montserrat);

