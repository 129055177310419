// ==========================================================
//  Layout - main
// ==========================================================
/*.l-page {}
@include _mq-down(md) {
    .l-page {}
}*/

/*icomoon*/
@font-face {
  font-family: 'icf-';
  src:  url('../../assets/doc/fonts/icf-.eot?aah92l');
  src:  url('../../assets/doc/fonts/icf-.eot?aah92l#iefix') format('embedded-opentype'),
    url('../../assets/doc/fonts/icf-.ttf?aah92l') format('truetype'),
    url('../../assets/doc/fonts/icf-.woff?aah92l') format('woff'),
    url('../../assets/doc/fonts/icf-.svg?aah92l#icf-') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icf-' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
i::before {
    line-height: 1;
    vertical-align: middle;
}

.icf-link-arrow:before {
  content: "\e900";
}
.icf-map:before {
  content: "\e901";
}
.icf-target:before {
  content: "\e902";
}
