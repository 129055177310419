 /* #Text
   -------------------------------------------------------------------------- */
/*doc
---
name: Mincho
category: Component
tag: Text
---

サイト全体で使うテキストスタイルを定義します。

```ejs
<p class="c-text-mincho">ダミーテキストです。</p>
```
*/

.c-text-mincho {
  font-family: $font-family-serif;
}
.c-text-gothic {
  font-family: $font-family-sans-serif;
}
.c-text-Montserrat { /*Gothemの代わり*/
    font-family: 'Montserrat', sans-serif;
}
.c-text-Poppings {
    font-family: 'Poppins', sans-serif;
}
.c-text-Time {
    font-family: 'Times New Roman', sans-serif;
}

.c-color-red {
    color: #ff0000;
}

.c-color-brand {
    color: $color-brand;
}

.c-color-text {
    color: $color-text !important;
}

.c-text-bold {
    font-weight: bold;
}

.c-unbar-brand {
    border-bottom: 1px solid $color-brand;
}
