 /* #Footer
   -------------------------------------------------------------------------- */
/*.l-footer {}
.l-footer__copryright-area {}
.l-footer__copryright {}
*/
.l-footer {
    margin-top: calc(200vw / 13.66);
    background: #3c3c3c;
    padding-bottom: calc(70vw / 13.66);
    font-size: 1.3rem;
    &__wrap {
        margin-top: calc(100vw / 13.66);
        max-width: 1190px;
        margin: 0 auto;
        padding-right: 30px;
        padding-left: 30px;
    }
    &__upper {
        margin-bottom: 23px;
        align-items: flex-end;
    }
    &__company {
        margin-bottom: 13px;
    }
    &__logo {
        width: 247px;
        height: 26px;
    }
    &__phone {
        color: #fff;
        & .phone-ttl {
            color: #323232;
            font-weight: bold;
            background: #fff;
            height: 22px;
            width: 120px;
            border-radius: 11px;
            margin: 32px 0 17px 0;
        }
        & .freedial {
            margin-left: 10px;
        }
        & .kanazawa .freedial {
            width: 286px;
            height: 30px;
            display: block;
            & a {
                display: block;
            }
        }
        & .reception {
            font-weight: bold;
        }
        & .fukui {
            margin-top: 21px;
        }
        & .fukui .freedial {
            width: 290px;
            height: 30px;
            display: block;
            & a {
                display: block;
            }
        }
        & .reception {
            margin-top: 15px;
        }
    }
    &__adress {
        text-align: center;
    }
    &__group {
        line-height: 23px;
        & .hakusan {
            margin-top: 23px;
        }
        & .fukui {
            margin-top: 23px;
        }
        & .campus {
            color: #fff;
            font-weight: bold;
        }
        & .campus a {
            color: #fff;
            margin-left: 7px;
        }
        & .campus .icf-map::before {
            color: #f7931e;
            padding-right: 3px;
        }
        & .adress {
            color: #fff;
        }
        & .link a {
            color: #fff;
        }
        & .link .icf-link-arrow::before {
            font-size: 7px;
            padding-right: 4px;
            color: #f7931e;
        }
        & .link .icf-target::before {
            font-size: 10px;
            padding-left: 5px;
            color: #808080;
        }
    }
    &__lower {
        border-top: 1px solid #646464;
        line-height: 1;
        padding-top: 28px;
        padding-left: 7px;
        & ul li {
            letter-spacing: 0.04em;
        }
        & ul li + li {
            margin-left: 23px;
        }
        & ul li a {
            color: #fff;
        }
        & .icf-link-arrow::before {
            font-size: 7px;
            padding-right: 4px;
        }
        /*ホバー時の挙動
        & ul li a {
            position: relative;
            display: inline-block;
            text-decoration: none;
        }
        & ul li a::after {
            position: absolute;
            bottom: -4px;
            left: 0;
            content: '';
            width: 100%;
            height: 1px;
            background: #fff;
            transform: scale(0, 1);
            transform-origin: left top;
            transition: transform .3s;
        }
        & ul li a:hover::after {
            transform: scale(1, 1);
        }
        */
    }
    &__copy {
        text-align: right;
        color: #fff;
    }
}

@include _mq-down(lg) { /*タブレット縦用*/
    .l-footer {
        &__phone {
            & .kanazawa .freedial {
                width: 269px;
                height: 29px;
            }
            & .fukui .freedial {
                width: 273px;
                height: 29px;
            }
        }
        &__group {
            font-size: 1.2rem;
        }
        &__lower {
            padding-left: 0;
            font-size: 1.2rem;
            padding-top: 1px;
            & li {
                width: auto;
                letter-spacing: 0;
                margin-top: 12px;
            }
            & li + li {
                margin-left: 18px;
            }
        }
        &__copy {
            width: 100%;
            text-align: center;
            border-top: 1px solid #646464;
            padding-top: 12px;
            margin-top: 15px;
        }
    }
}

@include _mq-down(md) {
    .l-footer {
        margin-top: calc(100vw / 7.68);
        padding-bottom: 30px;
        &__wrap {
            padding-top: 40px;
            width: 100%;
        }
        &__logo {
            margin: 0 auto;
        }
        &__company {
            border-bottom: 1px solid #646464;
            padding-bottom: 25px;
            margin-bottom: 25px;
        }
        &__phone {
            & .phone-ttl {
                font-size: 1.1rem;
                width: 315px;
                margin-right: auto;
                margin-left: auto;
            }
            & .freedial {
                margin-left: 8px;
            }
            & .kanazawa .freedial {
                width: 269px;
                height: 29px;
            }
            & .fukui .freedial {
                width: 273px;
                height: 29px;
            }
            & .reception {
                text-align: center;
            }
        }
        &__group {
            font-size: 1.2rem;
        }
        &__lower {
            padding-left: 0;
            font-size: 1.2rem;
            padding-top: 1px;
            & li {
                width: auto;
                letter-spacing: 0;
                margin-top: 12px;
            }
            & li + li {
                margin-left: 18px;
            }
        }
        &__copy {
            width: 100%;
            text-align: center;
            border-top: 1px solid #646464;
            padding-top: 12px;
            margin-top: 15px;

        }
    }
}

@include _mq-down(sm) {
    .l-footer {
        margin-top: calc(100vw / 3.75);
        &__phone {
            & .phone-ttl {
                font-size: 1.1rem;
                width: 100%;
            }
        }
        &__lower {
            & li.info {
                margin-left: 0;
            }
        }
    }
}


.l-top-footer {
    & ul {
        width: calc(1000vw / 13.66);
        margin: 0 auto;
        @include _mq-down(sm) {
            width: 100%;
            margin: 0;
        }
    }
    &__upper {
        background-color: #d9d9d9;
        font-size: calc(14vw / 13.66);
        text-align: center;
        line-height: 1.3;
        letter-spacing: 0.1em;
        & ul {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            height: calc(50vw / 13.66);
            border-right: 1px solid #fff;
            border-left: 1px solid #fff;
        }
        & li {
            width: calc(100% / 6);
            & a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
            }
        }
        @include _mq-up(sm) {
            & li + li {
                border-left: 1px solid #fff;
            }
        }
    }
    &__lower {
        font-size: calc(13vw / 13.66);
        letter-spacing: 0.1em;
        background-color: #fff;
        line-height: 1.78571;
        @include _mq-up(sm) {
            & ul {
                display: flex;
                justify-content: center;
                padding: 10px 0;
            }
            & ul + ul {
                border-top: 1px solid #bbb;
            }
            & li {
                padding: 0 calc(15vw / 13.66);
                position: relative;
            }
            & li + li  {
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    background-color: #bbb;
                    width: 1px;
                    height: 15px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
        }
    }
    @include _mq-down(sm) {
        &__upper {
            font-size: calc(12vw / 3.75);
            & ul {
                height: 100%;
                border-right: 0;
                border-left: 0;
            }
            & li {
                width: calc(100% / 2);
                height: 45px;
                border-bottom: 1px solid #fff;
                &:nth-child(even) {
                    border-left: 1px solid #fff;
                }
            }
        }
        &__lower {
            font-size: calc(12vw / 3.75);
            display: flex;
            justify-content: space-between;
            padding: 20px 30px;
            & ul {
                display: block;
                margin: 0;
            }
            & li + li {
                width: auto;
                margin-top: 3px;
            }
            .left {
                width: _vw(130, 375);
            }
            .right {
                width: _vw(170, 375);
            }
        }
    }
}
