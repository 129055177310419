// Object - project - nav
.l-method {
    //width: _vw(900);
    width: _vw(1066);
    margin-right: auto;
    margin-left: auto;
    @include _mq-down(sm) {
        width: 100%;
    }
    nav.Method {
        margin-top: _vw(90);
        @include _mq-down(sm) {
            margin-top: 0;
        }
        ul {
            display: flex;
            //-webkit-box-pack: center;
            //-ms-flex-pack: center;
            //justify-content: center;
            @include _mq-down(sm) {
                flex-wrap: wrap;
                border-bottom: 1px solid $color-brand;
            }
            li {
                @include _mq-down(sm) {
                    width: 50%;
                    border-top: 1px solid $color-brand;
                    &:nth-child(5) {
                        width: 100%;
                    }
                    &:nth-child(even) {
                        border-left: 1px solid $color-brand;
                    }
                }
                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: _vw(213);
                    height: _vw(70);
                    color: $color-brand;
                    font-size: _vw(15);
                    font-weight: bold;
                    position: relative;
                    letter-spacing: 0.1em;
                    @include _mq-down(sm) {
                        font-size: _vw(13, 375);
                        font-weight: normal;
                        width: 100%;
                        height: _vw(40, 375);
                    }
                }
                a::before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: #fff;
                    border: 1px solid $color-brand;
                    transform: skewX(16deg);
                    @include _mq-down(sm) {
                        display: none;
                    }
                }
                @include _mq-up(md) {
                    a {
                        transition: color 0.2s;
                        &::before {
                            transition: background-color 0.2s;
                        }
                    }
                    a:hover {
                        opacity: 1;
                        color: #fff;
                        &::before {
                            background-color: $color-brand;
                        }
                    }
                }
                & + li a::before {
                    border-left: 0;
                }
                &.current a {
                    color: #fff;
                    pointer-events: none;
                    &::before {
                        background-color: $color-brand;
                    }
                    @include _mq-down(sm) {
                        background-color: $color-brand;
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
