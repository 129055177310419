// Object - project -

.bgc-Pet-kanazawa {
    background-color: #29abe2;
}
.bgc-Pet-fukui {
    background-color: #29abe2;
}
.bgc-Pet-kango {
    background-color: #ff85b9;
}
.bgc-Bisen {
    background-color: #c1272d;
}
.bgc-HoteBra {
    background-color: #1564a0;
}
.bgc-Sweets {
    background-color: #8c6239;
}
.bgc-Cooking {
    background-color: #8e8887;
}
.bgc-Medical {
    background-color: #fbb03b;
}
.bgc-Cycle {
    background-color: #009245;
}
.bgc-Food {
    background-color: #000080;
}
.bgc-admin {
    background-color: #ccc;
}

.bar-Pet-kanazawa {
    border: 1px solid #29abe2;
}
.bar-Pet-fukui {
    border: 1px solid #29abe2;
}
.bar-Pet-kango {
    border: 1px solid #ff85b9;
}
.bar-Bisen {
    border: 1px solid #c1272d;
}
.bar-HoteBra {
    border: 1px solid #1564a0;
}
.bar-Sweets {
    border: 1px solid #8c6239;
}
.bar-Cooking {
    border: 1px solid #8e8887;
}
.bar-Medical {
    border: 1px solid #fbb03b;
}
.bar-Cycle {
    border: 1px solid #009245;
}
.bar-Food {
    border: 1px solid #000080;
}

.txt-Pet-kanazawa {
    color: #29abe2;
}
.txt-Pet-fukui {
    color: #29abe2;
}
.txt-Pet-kango {
    color: #ff85b9;
}
.txt-Bisen {
    color: #c1272d;
}
.txt-HoteBra {
    color: #1564a0;
}
.txt-Sweets {
    color: #8c6239;
}
.txt-Cooking {
    color: #8e8887;
}
.txt-Medical {
    color: #fbb03b;
}
.txt-Cycle {
    color: #009245;
}
.txt-Food {
    color: #000080;
}

table.default-table {
    font-size: _vw(14);
    line-height: 2;
    letter-spacing: 0.05em;
    th {
        vertical-align: top;
    }
    td {
        //padding-left: 1em;
    }
    @include _mq-down(sm) {
        font-size: _vw(12, 375);
    }
}
