 /* #Header
   -------------------------------------------------------------------------- */
/*.l-header {}
.l-header__logo-area {}
.l-header__logo {}
.l-header__nav-area {}
.l-header__nav {}
.l-header__nav-item {}
.l-header__nav-link {}
.l-header__background{}
*/

/*ヘッダー*/
.l-header {
    width: 100%;
    /*background: #fff;*/
    position: absolute;
    top: 0;
    z-index: 999;
    &__wrap {
        width: _vw(1266);
        margin: 0 auto;
        position: relative;
        height: _vw(106);
    }
    &__logo {
        width: _vw(247);
        height: _vw(26);
        position: absolute;
        left: 0;
        top: _vw(50);
        line-height: 1;
    }
    &__logo a {
        display: block;
    }
    &__logo img {
        display: block;
    }
}
/*.l-header__wrap {
    transition: 0.4s;
}
.l-header__wrap.is-white {
    background: #fff;
}*/

@include _mq-down(md) {
    .l-header {
        height: 60px;
        position: static;
        &__wrap {
            width: 100%;
            height: 60px;
            /*background: #fff;*/
            position: fixed;
            top: 0;
            z-index: 9999;
            height: 60px;
            background: #fff;
        }
        &__logo {
            width: 186px;
            height: auto;
            top: 29px;
            left: 30px;
        }
    }
}


/*PCナビゲーション
.l-gnav {
    width: _vw(930);
    position: absolute;
    right: 0;
    top: 0;
}
.l-gnav > ul {
    display: flex;
    justify-content: right;
    align-items: center;
    height: 70px;
}
.l-gnav > ul > li {
    padding: 5px 15px;
    line-height: 1.4;
}
.l-gnav > ul > li + li {
    border-left: 1px solid #ccc;
}

@include _mq-down(md) {
    .l-gnav {
        display: none;
    }
}
*/
/*l-gnav-toggle:メニューボタン*/
.l-gnav-toggle-area {
    /*float: right;*/
    width: 70px;
    height: 70px;
    background-color: #fff;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    /*position: relative;*/
    padding: 0;
    z-index: 9999;
    display: block;
    position: fixed;
    right: 0;
    top: 0;
    cursor: pointer;
    @include _mq-down(md) {
        width: 60px;
        height: 60px;
    }
}

.l-gnav-toggle {
    width: 26px;
    height: 22px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    top: 19px;
    > span {
        position: absolute;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 2px;
        background-color: #999;
        transition: 0.2s $ease-out-cubic;
        transform-origin: center;
    }
    > span:nth-child(1) {
        top: 0px;
    }
    > span:nth-child(2) {
        top: 9px;
    }
    > span:nth-child(3) {
        top: 18px;
    }
    @include _mq-down(md) {
        top: 17px;
        > span:nth-child(1) {
        top: 0px;
        }
        > span:nth-child(2) {
            top: 7px;
        }
        > span:nth-child(3) {
            top: 14px;
        }
    }
}

/*l-gnav-toggle:メニューボタン*/
.l-gnav-menu-area {
    position: fixed;
    z-index: 9997;
    /*top: 70px;*//*メニューボタン分下げる場合*/
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transform: translate3d(0, -105%, 0);
    transition: 0.4s $ease-out-cubic;
    background-color: #fff;
    padding-top: _vw(50);
    padding-right: _vw(200);
    padding-bottom: _vw(50);
    padding-left: _vw(95);
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include _mq-down(sm) {
        display: block;
        transform: translate3d(105%, 0, 0);
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        height: 100vh;
        /*height: calc(100vh - 70px);*//*メニュー中身スクロール時、はスマホ下の部分に隠れないようにするため*/
    }
    @include _mq-down(sm) {
        margin-top: 60px;
        padding: 30px 30px 150px;
    }
}

/*メニューボタン MENU/CLOSE*/
.l-gnav-toggle-text {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 0;
    height: 70px;
    font-size: 0.8rem;
    line-height: 1;
    letter-spacing: normal;
    font-weight: bold;
    color: #333;
    @include _mq-down(md) {
        height: 60px;
    }
    &__menu,
    &__close {
        letter-spacing: 0.05em;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: inline-block;
        bottom: 15px;
        transition: 0.4s $ease-in-out;
        @include _mq-down(md) {
            bottom: 12px;
        }
    }
    &__close {
        opacity: 0;
    }
}

/*メニューボタンをクリックした時にis-drawer-openを付与*/
.is-drawer-open {
    .l-gnav-menu-area {
        transform: translate3d(0, 0, 0);
    }
    .l-gnav-toggle {
        > span:nth-child(1) {
            transform: translate3d(0, 9px, 0) rotate(45deg);
        }
        > span:nth-child(2) {
            transform: scale(0);
            opacity: 0;
        }
        > span:nth-child(3) {
            transform: translate3d(0, -9px, 0) rotate(-45deg);
        }
        @include _mq-down(md) {
            > span:nth-child(1) {
                transform: translate3d(0, 7px, 0) rotate(45deg);
            }
            > span:nth-child(2) {
                transform: scale(0);
                opacity: 0;
            }
            > span:nth-child(3) {
                transform: translate3d(0, -7px, 0) rotate(-45deg);
            }
        }
    }
    .l-gnav-toggle-text {
        &__menu {
            opacity: 0;
        }
        &__close {
            opacity: 1;
        }
    }
    .l-gnav-background {
        /*visibility: visible;*/
        background: transparent;
        width: 100vw;
        height: 100vh;
        position: fixed;
        right: 0;
        z-index: 9996;
        transition: 0.4s $ease-in-out;
    }
}

/* l-gnav-menu:メニューボタンの中身 */

@include _mq-up(sm) {
    .l-gnav-menu {
        //display: flex;
        &__logo {
            width: _vw(357);
            height: _vw(28);
        }
        &__link {
            display: flex;
            height: _vw(570);
        }
        &.left {
            text-align: right;
            border-right: 1px solid #ccc;
            padding-right: _vw(60);
            font-size: _vw(22);
            line-height: 2.5;
            letter-spacing: 0.1em;
        }
        &.right {
            padding-left: _vw(60);
            font-size: _vw(16);
            line-height: 2.8125;
            display: flex;
            flex-flow: column;
            justify-content: flex-end;
            & a {
                display: flex;
                align-items: center;
            }
            & .icf-target {
                color: #808080;
                font-size: _vw(12);
                padding-left: _vw(5);
                display: block;
                &:before {
                    vertical-align: top;
                }
            }
        }
    }
    .l-gnav-menu {
        & li:nth-child(1) {
            transition: 0.2s;
            transition-delay: 0.05s;
        }
        & li:nth-child(2) {
            transition: 0.4s;
            transition-delay: 0.1s;
        }
        & li:nth-child(3) {
            transition: 0.4s;
            transition-delay: 0.15s;
        }
        & li:nth-child(4) {
            transition: 0.4s;
            transition-delay: 0.2s;
        }
        & li:nth-child(5) {
            transition: 0.4s;
            transition-delay: 0.25s;
        }
        & li:nth-child(6) {
            transition: 0.4s;
            transition-delay: 0.3s;
        }
        & li:nth-child(7) {
            transition: 0.4s;
            transition-delay: 0.35s;
        }
        & li:nth-child(8) {
            transition: 0.4s;
            transition-delay: 0.4s;
        }
        & li:nth-child(9) {
            transition: 0.4s;
            transition-delay: 0.45s;
        }
        & li:nth-child(10) {
            transition: 0.4s;
            transition-delay: 0.5s;
        }
        & li:nth-child(11) {
            transition: 0.4s;
            transition-delay: 0.55s;
        }
        &.left {
            & li {
                opacity: 0;
                transform: translateX(-100px);
            }
        }
        &.right {
            & li {
                opacity: 0;
                transform: translateX(100px);
            }
        }
    }
    .is-drawer-open .l-gnav-menu {
        & li {
            opacity: 1;
            transform: translateX(0);
        }
    }
}

@include _mq-down(sm) {
    .l-gnav-menu {
        &__logo {
            display: none;
        }
        & li {
            height: 40px;
        }
        & a {
            display: flex;
            align-items: center;
        }
        & .icf-target {
            color: #808080;
            font-size: _vw(9, 375);
            padding-left: _vw(4, 375);
            display: block;
            &:before {
                vertical-align: top;
            }
        }
    }
}

/**
* スマホ時メニュー内の項目sub-inner
@include _mq-down(md) {
    .l-gnav-menu__sub-inner {
        display: none;
        background-color: #fff;
        a {
            display: block;
            color: $color-brand;
            padding-left: 20px;
            border-bottom: 1px solid $color-brand;
        }
        & li:last-child a {
            border-bottom: 0;
        }
        &.is-open {
            display: block;
        }
    }
}
**/
/**
* フローティングバナー
**/
.flt-banner {
    width: 70px;
    position: fixed;
    top: 70px;
    right: 0;
    z-index: 9990;
    &__item {
        background: #fff;
    }
    &__item a {
        color: #fff;
        height: 130px;
        width: 70px;
        background: #f7931e;
        border-top: 1px solid #fff;
        writing-mode: vertical-rl;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.0rem;
        position: relative;
        line-height: 1.3;
    }
    &__item a::before {
        content: '';
        position: absolute;
        top: 15px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    &__item a span {
        margin-top: 20px;
    }
    & .icon-o-campus::before {
        background: url(../../assets/img/common/icon_side_open-campus.svg) no-repeat;
        width: 19px;
        height: 22px;
    }
    & .icon-request::before {
        background: url(../../assets/img/common/icon_side_request.svg) no-repeat;
        width: 19px;
        height: 22px;
    }
    & .icon-tyoi-cam::before {
        background: url(../../assets/img/common/icon_side_tyoi-cam.svg) no-repeat;
        width: 19px;
        height: 22px;
    }
    & .icon-map::before {
        background: url(../../assets/img/common/icon_side_map.svg) no-repeat;
        width: 19px;
        height: 22px;
    }
}


